
import { resetRouter } from '@/router'
import Vue from 'vue'
import { uuid } from '@/utils'
import requestUrl from '@/api/index'

const { login, AuthLoginByCode, logout } = requestUrl

const state = {
    Authorization: '',
    name         : '',
    avatar       : '',
    introduction : '',
    roles        : [],
    rules        : []
}
// import Cookies from 'js-cookie'

const mutations = {
    SET_TOKEN: (state, token) => {
        state.Authorization = token
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_RULES: (state, rules) => {
        state.rules = rules
    }
}

const actions = {
    // user login
    login({ commit, dispatch }, userInfo) {
        const { username, password, randstr,
            ticket, ga } = userInfo

        return new Promise((resolve, reject) => {
            Vue.ls.remove('deviceId')
            const deviceId = uuid()
            $request.postRequest({
                url   : login,
                params: {
                    mobile  : username.trim(),
                    password: password,
                    randstr,
                    ga,
                    ticket
                },
                headers: {
                    'Device-Id': deviceId
                }
            }).then(async response => {
                Vue.ls.set('deviceId', deviceId)

                const { userInfo, rule, role } = response.data
                await dispatch('SetToken', response.data)
                await dispatch('setUserInfo', userInfo)
                Vue.ls.set('rules', rule)
                Vue.ls.set('role', role)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // user login
    loginByCode({ commit, dispatch }, userInfo) {
        const { code, company_id } = userInfo

        return new Promise((resolve, reject) => {
            Vue.ls.remove('deviceId')
            $request.postRequest({
                url   : AuthLoginByCode,
                params: {
                    code, company_id
                },
                headers: {
                    'Device-Id': code
                }
            }).then(async response => {
                Vue.ls.set('deviceId', code)
                const { userInfo, rule, role } = response.data
                await dispatch('SetToken', response.data)
                await dispatch('setUserInfo', userInfo)
                Vue.ls.set('rules', rule)
                Vue.ls.set('role', role)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 设置用户信息
    setUserInfo({
        commit, dispatch
    }, userInfo) {
        const { name, avatar } = userInfo
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        // commit('SET_INTRODUCTION', introduction)
    },
    // 设置token
    SetToken({
        commit, dispatch
    }, response) {
        return new Promise((resolve, reject) => {
            const { userInfo } = response
            // setToken(userInfo.Authorization)
            Vue.ls.set('userInfo', userInfo)
            // Cookies.set('jwt', userInfo.Authorization, {})
            commit('SET_TOKEN', userInfo.Authorization)
            resolve(response)
        })
    },

    // user logout
    logout({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.postRequest({
                url: logout

            }).then(() => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                Vue.ls.remove('userInfo')
                // removeToken()
                resetRouter()
                // reset visited views and cached views
                // dispatch('tagsView/delAllViews', null, { root: true })
                dispatch('permission/delRoutes', false, { root: true })

                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            Vue.ls.remove('userInfo')
            resetRouter()
            // Cookies.remove('jwt')
            // removeToken()
            resolve()
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
