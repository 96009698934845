<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import qs from 'qs'
import { getWxConf } from '@/utils/wx'

import { AES_ECB_ENCRYPT } from '@/utils/aes'
// 改进后的事件监听
window.onload = function() {
    document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
            event.preventDefault()
        }
    }, {
        passive: false // 关闭被动监听
    })
    let lastTouchEnd = 0
    document.addEventListener('touchend', function(event) {
        const now = (new Date()).getTime()
        if (now - lastTouchEnd <= 300) {
            event.preventDefault()
        }
        lastTouchEnd = now
    }, false)
}

export default {
    name: 'App',
    data() {
        return {
            code: ''
        }
    },
    beforeCreate() {

    },
    created() {
        // 本地开发模拟数据
        // this.setData()
        if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
            window.entryUrl = location.href.split('#')[0]
        }
        this.getCode()
    },
    methods: {
        setData() {
            this.$ls.set('openid', 'aDCZCVf9T9vJePm4nbfEFaREXxOoIK2vgsN2p+4HpBo=')
            this.$ls.set('userInfo', { 'openid': 'o1AA36-QYwnQzpp665lEYYJ1bxgU', 'nickname': 'Yu', 'sex': 0, 'language': '', 'city': '', 'province': '', 'country': '', 'headimgurl': 'https://wx.qlogo.cn/mmhead/JIsrcXsHB4gnkL5sXpS3tsNNFMKiasILxSLdJZXAyhtk/0', 'privilege': [] })
        },
        /**
     * @description: 获取授权code
     * @param {*}
     * @return {*}
     */
        getCode() {
            // 从 window.location.href 中截取 code 并且赋值
            // window.location.href.split('#')[0]
            if (window.location.href.indexOf('state') !== -1) {
                this.code = qs.parse(
                    window.location.href.split('#')[0].split('?')[1]
                ).code
            }

            if (this.code || this.$ls.get('openid')) {
                // 存在 code 直接调用接口
                if (this.code && !this.$ls.get('openid')) {
                    this.handGetUserInfo(this.code)
                } else {
                    getWxConf()
                }
            } else {
                this.handLogin()
            }
        },
        /**
     * @description: 获取用户授权登陆
     * @param {*}
     * @return {*}
     */
        handLogin() {
            // 重定向地址重定到当前页面，在路径获取 code
            const hrefUrl = window.location.href
            // const hrefUrl = window.location.href.split('#')[0]

            if (this.code === '') {
                window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx28bec0cc70a23305&redirect_uri=${encodeURIComponent(hrefUrl)}&response_type=code&scope=snsapi_userinfo&state=h5#wechat_redirect`) // 正式
                // window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx73c3241df112a7fc&redirect_uri=${encodeURIComponent(hrefUrl)}&response_type=code&scope=snsapi_userinfo&state=h5#wechat_redirect`) // 预发/装小白
            }
        },

        /**
     * @description: 获取用户信息
     * @param {*} code
     * @return {*}
     */
        async   handGetUserInfo(sendCode) {
            // 调用后台接口
            // getOpenId
            const { data, code } = await $request.getRequest({
                url   : this.$requestUrl.main.getUserInfo,
                params: {
                    code: sendCode
                }
            })
            if (code === 2000) {
                this.$ls.set('openid', AES_ECB_ENCRYPT(data.openid, 'external-decode-params'))
                this.$ls.set('userInfo', data)
                // this.$router.push({ path: '/user' })
                // window.location.href = '/#/user'
                getWxConf()
                return
            }
            // this.$ls.set('openid', AES_ECB_ENCRYPT('o1AA36-QYwnQzpp665lEYYJ1bxgU', 'external-decode-params'))
        }

    }

}
</script>
<style>
#app{
    /* padding:10px; */
}
</style>
