
import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
// import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
// import Vue from 'vue'
// import { setTimeout } from 'core-js'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

// const whiteList = ['/login', '/auth-redirect', '/contact-me'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
    // console.log('from', from)
    // console.log('to', to)
    // start progress bar

    NProgress.start()

    // set page title
    document.title = getPageTitle(to.meta.title)
    // determine whether the user has logged in
    // const userInfo = Vue.ls.get('userInfo', {})
    // const u = navigator.userAgent
    // const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
    // // XXX: 修复iOS版微信HTML5 History兼容性问题
    // if (isiOS && to.path !== location.pathname) {
    //     // 此处不可使用location.replace
    //     location.assign(to.fullPath)
    // } else {
    next()
    // }
    NProgress.done()
    // const redirect = decodeURIComponent(from.query.redirect || to.path)
    // if (to.path === redirect) {
    //     next({ ...to, replace: true })
    // } else {
    //     next({ path: redirect })
    // }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
