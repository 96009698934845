import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [

    {
        path     : '/404',
        component: () => import('@/views/error-page/404'),
        hidden   : true
    },
    {
        path     : '',
        name     : 'top',
        component: Layout,
        meta     : {
            title: '首页'
        },
        redirect: '/user',
        children: [
            {
                name     : 'index',
                path     : 'index',
                component: () => import('@/views/index/index.vue'),
                meta     : {
                    title: '首页'
                }
            },
            {
                name     : 'info',
                path     : 'info',
                component: () => import('@/views/info'),
                meta     : {
                    title: '工地信息'
                }
            },
            {
                name     : 'user',
                path     : '/',
                component: () => import('@/views/user'),
                meta     : {
                    title: '个人中心'
                }
            },
            {
                name     : 'contract',
                path     : 'contract',
                component: () => import('@/views/contract'),
                meta     : {
                    title: '合同信息'
                }
            },
            {
                name     : 'outside',
                path     : 'outside',
                component: () => import('@/views/outside'),
                meta     : {
                    title: '套外合同'
                }
            },
            {
                name     : 'record',
                path     : 'record',
                component: () => import('@/views/record'),
                meta     : {
                    title: '选材记录'
                }
            },
            {
                name     : 'confirm',
                path     : 'confirm',
                component: () => import('@/views/confirm'),
                meta     : {
                    title: '确认选材'
                }
            },
            {
                name     : 'change',
                path     : 'change',
                component: () => import('@/views/change'),
                meta     : {
                    title: '变更记录'
                }
            },
            {
                name     : 'change-confirm',
                path     : 'change-confirm',
                component: () => import('@/views/change-confirm'),
                meta     : {
                    title: '确认变更'
                }
            },
            {
                name     : 'progress',
                path     : 'progress',
                component: () => import('@/views/progress'),
                meta     : {
                    title: '工地进度'
                }
            },
            {
                name     : 'plan',
                path     : 'plan',
                component: () => import('@/views/plan'),
                meta     : {
                    title: '施工计划'
                }
            },
            {
                name     : 'settlement-confirmation',
                path     : 'settlement-confirmation',
                component: () => import('@/views/settlement-confirmation'),
                meta     : {
                    title: '中期结算'
                }
            },
            {
                name     : 'survey-sheet',
                path     : 'survey-sheet',
                component: () => import('@/views/survey-sheet'),
                meta     : {
                    title: '测量单'
                }
            },
            {
                name     : 'survey-confirm',
                path     : 'survey-confirm',
                component: () => import('@/views/survey-confirm'),
                meta     : {
                    title: '确认测量单'
                }
            },
            {
                name     : 'archives',
                path     : 'archives',
                component: () => import('@/views/archives'),
                meta     : {
                    title: '工地档案'
                }
            },
            {
                name     : 'loading',
                path     : 'loading',
                component: () => import('@/views/loading'),
                meta     : {
                    title: '等待中'
                }
            }
        ]

    }

]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
    // 404 page must be placed at the end !!!
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes        : constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
