import { constantRoutes } from '@/router'
import { treeToArray } from '@/utils'
import Layout from '@/layout'
import clonedeep from 'lodash.clonedeep'
import requestUrl from '@/api/index'
// import Qs from 'qs'
function creatRouterHttp(menuItem, baseFather = null) {
    if (menuItem.status && (menuItem.status === 1 || menuItem.status === 4)) {
        let obj = null
        obj = {
            name    : menuItem.name + menuItem.url,
            path    : menuItem.url,
            children: [],
            hidden  : menuItem.status === 4,
            meta    : {
                title: menuItem.title,
                icon : menuItem.icon
            },
            url: menuItem.url
        }

        if (baseFather) {
            obj.component = (resolve) => require([`@/views${menuItem.url}/index.vue`], resolve)
            if (menuItem.status === 4) { obj.meta.activeMenu = baseFather }
            // console.log('`@/views${menuItem.url}/index.vue`', `@/views${menuItem.url}/index.vue`)
        } else {
            obj.component = Layout
        }
        menuItem.children && menuItem.children.forEach((item) => {
            const child = creatRouterHttp(item, menuItem.url)
            child && obj.children.push(child)
        })
        return obj
    } else {
        return false
    }
}

function createAsyncRouter(queryList) {
    const async = []
    queryList.forEach(item => {
        const arr = creatRouterHttp(item)
        if (arr !== false) {
            async.push(arr)
        }
    })
    return redirectSet(async)
}
function redirectSet(menu) {
    const noRedirect = ['/engineering/substrate', '/materials/warehouse']
    if (Array.isArray(menu) && menu.length) {
        menu.forEach(item => {
            if (item.children && item.children.length) {
                if (!item.redirect && !noRedirect.includes(item.path)) {
                    // let i = -1
                    item.children.some(v => {
                        // i++
                        if (!v.hidden) return true
                    })
                    item.redirect = {
                        path: item.children[0].path
                    }
                }
                redirectSet(item.children)
            }
        })
    }
    return menu
}
// 获取所有页面下的按钮权限map
function getBtns(queryList) {
    const flatArray = treeToArray(clonedeep(queryList))

    const res = []
    flatArray.forEach(v => {
        if (v.status === 2 || v.status === 3) {
            res.push(v.name)
        }
    })

    return Array.from(new Set(res))
}
const state = {
    routes       : [],
    addRoutes    : [],
    btnRoles     : [],
    hasRoutes    : false,
    customerField: {}
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    },
    RESET_BtnROLES: (state) => {
        state.btnRoles = []
    },
    SET_BtnROLES: (state, obj) => {
        state.btnRoles = obj
    },
    SET_HASROUTES: (state, res) => {
        state.hasRoutes = res
    },
    SET_CUSTOMER_FIELD: (state, res) => {
        state.customerField = res
    }
}

const actions = {
    resetRules({ commit, dispatch }, res) {
        return new Promise(resolve => {
            commit('SET_HASROUTES', false)
            commit('RESET_BtnROLES')
            commit('SET_HASROUTES', [])
            resolve(res)
        })
    },
    delRoutes({ commit, dispatch }, res) {
        return new Promise(resolve => {
            commit('SET_HASROUTES', res)
            resolve(res)
        })
    },
    generateRoutes({ commit, dispatch }, rules) {
        return new Promise(resolve => {
            const data = createAsyncRouter(clonedeep(rules))

            data.push({ path: '*', redirect: '/404', hidden: true })
            const btns = getBtns(clonedeep(rules))
            commit('SET_ROUTES', data)
            commit('RESET_BtnROLES')
            commit('SET_BtnROLES', btns)
            commit('SET_HASROUTES', true)
            resolve(data)
        })
    },
    getMenu({ commit, dispatch }, roles) {
        return new Promise(resolve => {
            $request.getRequest({ url: 'https://www.fastmock.site/mock/f8ef46e7e5136005601fc3876187d810/role/getMenuList' }).then(res => {
                const data = createAsyncRouter(clonedeep(res.data))
                const btns = getBtns(clonedeep(res.data))
                resolve({ data, btns })
            })
        })
    },
    getLookCustomerInfoList({ commit, dispatch }, userInfo) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url   : requestUrl.getLookCustomerInfoList,
                params: {
                    company_id: userInfo.company_id,
                    user_id   : userInfo.user_id
                }
            }).then(async response => {
                const { data } = response
                const obj = {}
                const arr = treeToArray(data)
                arr.forEach(val => {
                    obj[val.name] = val.status
                })
                commit('SET_CUSTOMER_FIELD', obj)

                resolve(data)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
