import requestUrl from '@/api/index'
// import clonedeep from 'lodash.clonedeep'
// import { dataToArray, treeToArray, delEmptyChild } from '@/utils/index'
// import Vue from 'vue'
const state = {
    quote_config: {
        quote_type  : [],
        acreage_type: [],
        base_vl     : [], // 基装库
        warehouse   : [], // 材料库
        fee_type    : []// 收费方式
    },
    room_type: [
        { id: 1, name: '室' },
        { id: 2, name: '厅' },
        { id: 3, name: '厨' },
        { id: 4, name: '卫' },
        { id: 5, name: '阳' },
        { id: 6, name: '其他' },
        { id: 7, name: '全屋' }
    ]

}

const mutations = {

    SET_QUOTE_CONFIG: (state, data) => {
        for (const k in data) {
            state.quote_config[k] = data[k]
        }
    },
    SET_TAG_LIST: (state, data) => {
        state.tag_list = data
    }
}

const actions = {
    // 获取所有模板标签
    getTagList({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url   : requestUrl.quote.tagList,
                params: {
                    page    : 1,
                    pagesize: 100
                }
            }).then(async response => {
                const { data } = response
                commit('SET_TAG_LIST', data.list)
                resolve(data.list)
            })
        })
    },
    // 所有材料商 getAllSupplier
    getQuoteConfig({ commit, dispatch }, { c_type }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url   : requestUrl.quote.config,
                params: {
                    c_type
                }
            }).then(async response => {
                const { data } = response
                commit('SET_QUOTE_CONFIG', data)
                resolve(data)
            })
        })
    },
    // 选择基装
    getBaseLibrary({ commit, dispatch }, params) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.quote.baseLibrary,
                params
            }).then(async response => {
                const { data } = response

                resolve(data)
            })
        })
    },
    // 获取主材
    getMaterial({ commit, dispatch }, params) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.quote.material,
                params
            }).then(async response => {
                const { data } = response

                resolve(data)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
