import requestUrl from '@/api/index'
import clonedeep from 'lodash.clonedeep'
import { dataToArray, treeToArray, delEmptyChild } from '@/utils/index'
import Vue from 'vue'
const state = {
    statusList: {
        '1': '菜单',
        '2': '按钮',
        '3': '方法',
        '4': '隐藏菜单',
        '5': '数据',
        '6': '直接获取'
    },
    companyStatusList: {
        1: '正常',
        2: '审核中',
        0: '禁止'
    },

    customerSourceList  : [],
    customerSourceListKV: {},

    toStoreTypeList: [],
    adviserList    : [],

    accountManagerList: [],
    btnListKV         : {},
    btnListIdName     : {},
    designerDepList   : [],
    allCityList       : [],
    contractList      : [{
        name: '直签合同',
        id  : 1
    }, {
        name: '预售合同',
        id  : 2
    }, {
        name: '开工合同',
        id  : 3
    }, {
        name: '单设计合同',
        id  : 4
    }, {
        name: '半包合同',
        id  : 5
    }, {
        name: '套外销售合同',
        id  : 6
    }],
    feeRate           : [{ name: '套外销售合同收款', id: 1 }],
    ratioList         : [],
    receiptAccountList: [],
    customerStatusList: [],
    paymentTypeList   : [
        { id: 0, name: '现金' },
        { id: 1, name: '扫码' },
        { id: 2, name: '刷卡' },
        { id: 3, name: '银行转账' }
    ],
    custom_columns: {

    },
    all_brand         : [], // 品牌列表
    material_data     : [], // 材料类别
    all_supplier      : [], // 所有材料商
    version_repository: [], // 材料库列列表
    quote_status      : [
        { id: 1, label: '制作中' },
        { id: 2, label: '正常' },
        { id: 3, label: '已下架' }
    ],
    quote_type: [
        { id: '1', label: '清单报价' },
        { id: '2', label: '套餐报价' },
        { id: '3', label: '电器软装' }
    ]

}

const mutations = {
    ADD_ERROR_LOG: (state, log) => {
        state.logs.push(log)
    },

    SET_CUSTOMER_SOURCE_LIST: (state, data) => {
        state.customerSourceList = data
    },
    SET_CUSTOMER_SOURCE_LIST_KV: (state, data) => {
        state.customerSourceListKV = data
    },

    SET_ADVISER_LIST: (state, data) => {
        state.adviserList = data
    },

    SET_DESIGNER_DEP_LIST: (state, data) => {
        state.designerDepList = data
    },
    SET_ACCOUNTMANAGER_LIST: (state, data) => {
        state.accountManagerList = data
    },

    SET_BTN_LIST_KV: (state, data) => {
        state.btnListKV = data
    },
    SET_ALL_CITY_LIST: (state, data) => {
        state.allCityList = data
    },
    SET_RATIO_LIST: (state, data) => {
        state.ratioList = data
    },
    SET_RECEIPT_ACCOUNT: (state, data) => {
        state.receiptAccountList = data
    },

    SET_CUSTOMER_STATUS_LIST: (state, data) => {
        // state.storeListKV = data.kvData
        state.customerStatusList = data
    },
    SET_COLUMNS: (state, data) => {
        // state.storeListKV = data.kvData
        state.custom_columns = data
    },
    SET_ALL_BRAND: (state, data) => {
        // state.storeListKV = data.kvData
        state.all_brand = data
    },
    SET_MATERIAL_DATA: (state, data) => {
        // state.storeListKV = data.kvData
        state.material_data = data
    },
    SET_GET_VERSION_REPOSITORYS: (state, data) => {
        // state.storeListKV = data.kvData
        state.version_repository = data
    },
    SET_ALL_SUPPLIER: (state, data) => {
        state.all_supplier = data
    }
}
function dataToKeyVal(arr, key, val) {
    const obj = {}
    arr.forEach(v => {
        obj[v[key]] = v[val]
    })
    return obj
}

const actions = {

    // 获取自定义Columns
    getAllUserListRecord({ commit }, info) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.getAllUserListRecord
            }).then(async response => {
                const { data } = response
                commit('SET_COLUMNS', data)
                resolve(data)
            })
        })
    },
    // 获取专员
    getCommissioner({ commit }, info) {
        return new Promise((resolve, reject) => {
            // const company_id = Vue.ls.get('userInfo').company_id
            const params = {
                ...info
                // type       : info.type,
                // customer_id: info.customer_id,
                // is_all     : info.is_all,
                // search:info.search

            }
            $request.getRequest({
                url: requestUrl.getCommissioner,
                params
            }).then(async response => {
                const { data } = response
                resolve(data)
            })
        })
    },
    // 获取客户来源标签
    getCustomerSource({ commit }) {
        const company_id = Vue.ls.get('userInfo').company_id
        $request.getRequest({
            url: requestUrl.getCustomerSource(company_id)
        }).then(async response => {
            const { data } = response
            const newData = dataToArray(clonedeep(data))
            const arr = treeToArray(clonedeep(newData))
            const kvData = dataToKeyVal(arr, 'id', 'name')
            commit('SET_CUSTOMER_SOURCE_LIST', newData)
            commit('SET_CUSTOMER_SOURCE_LIST_KV', kvData)
        })
    },

    // 获取顾问经理
    getAdviserList({ commit }, par) {
        return new Promise((resolve, reject) => {
            const company_id = Vue.ls.get('userInfo').company_id
            const params = {
                type           : 2,
                is_leader      : 1,
                distribute_type: par.distribute_type,
                customer_num   : par.customer_num,
                customer_ids   : par.customer_ids
            }
            $request.getRequest({
                url: requestUrl.getAdviserList(company_id),
                params
            }).then(async response => {
                const { data } = response
                commit('SET_ADVISER_LIST', data)
                resolve(data)
            })
        })
    },
    // 获取设计部
    getDesignerDepartmentList({ commit }, par) {
        return new Promise((resolve, reject) => {
            const company_id = Vue.ls.get('userInfo').company_id
            const params = {
                type        : 1,
                is_leader   : 1,
                customer_ids: par.customer_ids
            }
            $request.getRequest({
                url: requestUrl.getAdviserList(company_id),
                params
            }).then(async response => {
                const { data } = response

                commit('SET_DESIGNER_DEP_LIST', data)
                resolve(data)
            })
        })
    },

    // 获取收费比例列表
    getRatioList({ commit }) {
        $request.getRequest({
            url: requestUrl.getRatioList
        }).then(async response => {
            const { data } = response
            commit('SET_RATIO_LIST', data)
        })
    },
    // 获取按钮列表
    getBtnList({ commit }) {
        const company_id = Vue.ls.get('userInfo').company_id
        $request.getRequest({
            url: requestUrl.getBtnList(company_id)
        }).then(async response => {
            const { data } = response
            const kvData = dataToKeyVal(data, 'id', 'name')
            commit('SET_BTN_LIST_KV', kvData)
        })
    },
    // 获取客户状态
    getCustomerStatus({ commit }) {
        $request.getRequest({
            url: requestUrl.getCustomerStatus()
        }).then(async response => {
            const { data } = response
            // const kvData = dataToKeyVal(data, 'id', 'name')
            commit('SET_CUSTOMER_STATUS_LIST', data)
        })
    },

    // 获取城市列表
    getAllCityList({ commit }) {
        $request.getRequest({
            url: requestUrl.getAllCityList
        }).then(async response => {
            const { data } = response
            const list = []
            for (const k in data) {
                list.push(data[k])
            }
            commit('SET_ALL_CITY_LIST', list)
        })
    },
    // 费率设置
    getReceiptAccount({ commit }, account_type) {
        $request.getRequest({
            url   : requestUrl.getReceiptAccount,
            params: {
                account_type
            }
        }).then(async response => {
            const { data } = response
            commit('SET_RECEIPT_ACCOUNT', data)
        })
    },
    getAllDic({ dispatch }) {
        dispatch('getCustomerSource')

        dispatch('getBtnList')
        dispatch('getAllCityList')
        dispatch('getRatioList')
        dispatch('getCustomerStatus')

        // dispatch('getAdviserList')
        // dispatch('getDesignerDepartmentList')
    },
    // 所有品牌列表
    getAllBrand({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.frp.getAllBrand

            }).then(async response => {
                const { data } = response

                commit('SET_ALL_BRAND', data)
                resolve(data)
            })
        })
    },

    // 材料分类
    getMaterialData({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.substrate.getMaterialData

            }).then(async response => {
                const { data } = response
                delEmptyChild(data)
                commit('SET_MATERIAL_DATA', data)
                resolve(data)
            })
        })
    },
    // 正常材料库列表
    getVersionRepositorys({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.frp.getVersionRepositorys

            }).then(async response => {
                const { data } = response

                commit('SET_GET_VERSION_REPOSITORYS', data)
                resolve(data)
            })
        })
    },
    // 所有材料商 getAllSupplier
    getAllSupplier({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.frp.getAllSupplier

            }).then(async response => {
                const { data } = response

                commit('SET_ALL_SUPPLIER', data)
                resolve(data)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
