
import Vue from 'vue'

export default function getPageTitle(pageTitle) {
    const title = Vue.ls ? Vue.ls.get('title', '梵客家装') : '梵客家装'
    if (pageTitle) {
        return `${title}-${pageTitle}`
    }
    return `${title}`
}
