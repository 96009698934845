
import requestUrl from '@/api/index'

import clonedeep from 'lodash.clonedeep'
const state = {
    craftType        : [],
    craftContent     : {},
    craftContentByTag: {},
    labelType        : [],
    labelContent     : {},
    labelContentByTag: {},

    houseList          : [], // 获取房屋类型列表 tag=2
    houseListKV        : {},
    customerLevelList  : [], // 获取客户级别标签列表tag=5
    customerLevelListKV: {},
    readyMoneyList     : [], // 获取装修预算标签列表tag=4
    readyMoneyListKV   : {},
    houseStyleList     : [], // 获取装修风格标签tag=1
    houseStyleListKV   : {},
    checkStaffList     : [], // 获取装修入住人员标签tag=3
    checkStaffListKV   : {},
    toStoreTypeList    : [], // 到店类型tag=8
    toStoreTypeListKV  : {}, // 到店类型tag=8
    deathList          : [], // 死单原因tag=7
    deathListKv        : {}, // 死单原因tag=7
    storeListKV        : {},
    storeList          : [], // 公司店面tag=6
    promotionsList     : []// 优惠活动
}
const labelTag = {
    1: {
        list: 'houseStyleList',
        kv  : 'houseStyleListKV',
        name: '房屋风格'
    },
    2: {
        list: 'houseList',
        kv  : 'houseListKV',
        name: '房屋类型'
    },
    3: {
        list: 'checkStaffList',
        kv  : 'checkStaffListKV',
        name: '入住人员'
    },
    4: {
        list: 'readyMoneyList',
        kv  : 'readyMoneyListKV',
        name: '装修预算'
    },
    5: {
        list: 'customerLevelList',
        kv  : 'customerLevelListKV',
        name: '客户级别'
    },
    6: {
        list: 'storeList',
        kv  : 'storeListKV',
        name: '公司店面'
    },
    7: {
        list: 'deathList',
        kv  : 'deathListKv',
        name: '死单原因'
    },
    8: {
        list: 'toStoreTypeList',
        kv  : 'toStoreTypeListKV',
        name: '到店类型'
    }

}
const mutations = {

    SET_CRAFT_TYPE: (state, data) => {
        state.craftType = data
    },
    SET_CRAFT_CONTENT: (state, data) => {
        const craftContent = state.craftContent
        const craftContentByTag = state.craftContentByTag
        craftContent[data.id] = data
        craftContentByTag[data.tag] = data.data
        state.craftContent = clonedeep(craftContent)
        state.craftContentByTag = clonedeep(craftContentByTag)
    },
    SET_LABEL_TYPE: (state, data) => {
        state.labelType = data
    },
    SET_LABEL_CONTENT: (state, data) => {
        // console.log('data', data)
        const labelContent = state.labelContent
        const labelContentByTag = state.labelContentByTag
        const tag = data.tag
        let list, kv
        if (labelTag[tag]) {
            list = labelTag[tag].list
            kv = labelTag[tag].kv
        }
        data.data = data.data || []
        data.data.forEach(v => {
            v.name = v.content
        })
        labelContent[data.id] = data
        labelContentByTag[data.tag] = data.data
        state.labelContent = clonedeep(labelContent)
        state.labelContentByTag = clonedeep(labelContentByTag)
        const kvData = dataToKeyVal(data.data, 'id', 'name')
        if (kv) {
            state[list] = data.data
            state[kv] = kvData
        }
    },
    SET_PROMOTIONS: (state, data) => {
        state.promotionsList = data
    }
}
function dataToKeyVal(arr, key, val) {
    const obj = {}
    arr.forEach(v => {
        obj[v[key]] = v[val]
    })
    return obj
}

const actions = {
    getAllSpeechCraftType({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.channel.getAllSpeechCraftType

            }).then(async response => {
                const { data } = response
                data.forEach(v => {
                    commit('SET_CRAFT_CONTENT', { data: v.content, id: v.id, tag: v.tag, name: v.name })
                })
                commit('SET_CRAFT_TYPE', data)
                resolve(data)
            })
        })
    },
    // 话术类型
    getSpeechCraftType({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.channel.getAllSpeechCraftType

            }).then(async response => {
                const { data } = response
                data.forEach(v => {
                    commit('SET_CRAFT_CONTENT', { data: v.content, id: v.id, tag: v.tag, name: v.name })
                })
                commit('SET_CRAFT_TYPE', data)
                resolve(data)
            })
        })
    },

    // 话术内容
    getSpeechCraftContent({ commit, dispatch }, info) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url   : requestUrl.channel.getSpeechCraftContent,
                params: {
                    speechcraft_type_id: info.id
                }
            }).then(async response => {
                const { data } = response

                commit('SET_CRAFT_CONTENT', { data, ...info })
                resolve(data)
            })
        })
    },

    // 获取所有字典内容
    getAllLabelContent({ commit, dispatch }, labelType) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.channel.getAllLabelContent

            }).then(async response => {
                const { data } = response
                // const content = labelType
                const res = []
                labelType.forEach(v => {
                    res.push({
                        data: data[v.tag],
                        id  : v.id,
                        tag : v.tag,
                        name: v.name
                    })
                })
                res.forEach(v => {
                    commit('SET_LABEL_CONTENT', v)
                })

                resolve(data)
            })
        })
    },
    // 字典类型
    getLabelType({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.channel.getLabelType

            }).then(async response => {
                const { data } = response
                dispatch('getAllLabelContent', data)
                commit('SET_LABEL_TYPE', data)
                resolve(data)
            })
        })
    },
    // 字典内容
    getLabelContent({ commit, dispatch }, info) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url   : requestUrl.channel.getLabelContent,
                params: {
                    label_type_id: info.id
                }
            }).then(async response => {
                const { data } = response

                commit('SET_LABEL_CONTENT', { data, ...info })
                resolve(data)
            })
        })
    },
    // 优惠列表
    getPromotions({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.channel.getPromotions

            }).then(async response => {
                const { data } = response

                commit('SET_PROMOTIONS', data)
                resolve(data)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

