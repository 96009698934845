import { domain } from './var'

const main = {
    getTicketSignature            : `${domain}/crm/mp/getTicketSignature`, // GET 获取签名
    userinfo                      : `${domain}/userinfo`, // GET
    getUserInfo                   : `${domain}/crm/mp/getUserInfo`, // GET
    getContractList               : `${domain}/crm/mp/getContractList`, // GET 合同列表
    getChangeRecords              : `${domain}/crm/mp/getChangeRecords`, // GET  变更记录
    getBatchChangeInfo            : `${domain}/crm/mp/getBatchChangeInfo`, // GET 变更记录详情
    confirmDuty                   : `${domain}/crm/mp/confirmDuty`, // POST 变更项审核
    getCustomerSelectMaterialList : `${domain}/crm/mp/getCustomerSelectMaterialList`, // GET  获取客户选材列表
    // customerConfirmMaterial       : `${domain}/crm/mp/customerConfirmMaterial`, // POST 确认选材
    customerConfirmMaterial       : `${domain}/crm/mp/customerSubmitConfirmSelectMaterial`, // POST 确认选材
    getSelectMaterialChangeRecords: `${domain}/crm/mp/getSelectMaterialChangeRecords`, // GET 选材记录
    getContractInfo               : `${domain}/crm/mp/getContractInfo`, // GET 合同详情
    bindAuthCode                  : `${domain}/crm/mp/bindAuthCode`, // POST 绑定授权码
    sendVerifyCode                : `${domain}/crm/mp/sendVerifyCode`, // POST 发送验证码
    bindCustomer                  : `${domain}/crm/mp/bindCustomer`, // POST 绑定手机号
    getReportProgressList         : `${domain}/crm/mp/getReportProgressList`, //
    getDrawList                   : `${domain}/crm/mp/getDrawList`, // 获取新老图纸列表

    getOpenId: `${domain}/getOpenId`, // GET
    unBind   : `${domain}/crm/mp/unBind`, // POST 解绑合同

    viewH5ConstructionPlan: `${domain}/crm/mp/viewH5ConstructionPlan`, // 施工计划
    getSettledInfo        : `${domain}/crm/mp/getSettledInfo`, // 客户结算详情
    customerCheckSettled  : `${domain}/crm/mp/customerCheckSettled`, // 客户确认中期结算

    getMeasureOrderList        : `${domain}/crm/mp/getMeasureOrderList`, // 测量单列表
    getMeasureOrderDetail      : `${domain}/crm/mp/getMeasureOrderDetail`, // 获取测量详情
    customerConfirmMeasureOrder: `${domain}/crm/mp/customerConfirmMeasureOrder` // 客户确认测量单

}
export default main
