
import requestUrl from '@/api/index'
const state = {
    companyList: []

}
// import Cookies from 'js-cookie'

const mutations = {
    SET_COMPANY_LIST: (state, list) => {
        state.companyList = list
    }

}

const actions = {

    getCompanyList({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url   : requestUrl.getCompanyList,
                params: { page: 1, pagesize: 1000 }
            }).then(async response => {
                const { data:{ list }} = response

                commit('SET_COMPANY_LIST', list)
                resolve(list)
            })
        })
    },
    getDepartmentList({ commit, state, dispatch }, company_id) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url   : requestUrl.departmentList,
                params: {
                    company_id
                }
            }).then(async response => {
                const { data } = response

                resolve(data)
            })
        })
    },
    getDepartmentTag({ commit, state, dispatch }, params) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.departmentTag,
                params
            }).then(async response => {
                const { data } = response
                resolve(data)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
