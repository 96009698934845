import CryptoJS from 'crypto-js'
// import sha1 from 'js-sha1'
// function bin2hex(str) {
//     let ret = ''
//     const r = /[0-9a-zA-Z_.~!*()]/
//     for (let i = 0, l = str.length; i < l; i++) {
//         if (r.test(str.charAt(i))) {
//             ret += str.charCodeAt(i).toString(16)
//         } else {
//             ret += encodeURIComponent(str.charAt(i)).replace(/%/g, '')
//         }
//     }
//     return ret
// }
function sha1Key(key) {
    const key1 = CryptoJS.SHA1(key).toString()
    const key2 = CryptoJS.SHA1(key1).toString()
    return key2.slice(0, 16)
}
/**
   * AES-256-ECB对称加密
   * @param text {string} 要加密的明文
   * @param secretKey {string} 密钥，
   * @returns {string} 加密后的密文，Base64格式
   */
export function AES_ECB_ENCRYPT(text, secretKey) {
    // console.log(' CryptoJS.enc', CryptoJS.enc)
    const keyHex = CryptoJS.enc.Utf8.parse(sha1Key(secretKey))

    const encrypted = CryptoJS.AES.encrypt(text, keyHex, {
        'mode'   : CryptoJS.mode.ECB,
        'padding': CryptoJS.pad.Pkcs7
    })

    return encrypted.toString()
}

/**
   * AES-256-ECB对称解密
   * @param textBase64 {string} 要解密的密文，Base64格式
   * @param secretKey {string} 密钥，43位随机大小写与数字
   * @returns {string} 解密后的明文
   */
export function AES_ECB_DECRYPT(textBase64, secretKey) {
    const keyHex = CryptoJS.enc.Base64.parse(secretKey)
    const decrypt = CryptoJS.AES.decrypt(textBase64, keyHex, {
        'mode'   : CryptoJS.mode.ECB,
        'padding': CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Utf8.stringify(decrypt)
}

/**
   * AES-256-CBC对称加密
   * @param text {string} 要加密的明文
   * @param secretKey {string} 密钥，43位随机大小写与数字
   * @returns {string} 加密后的密文，Base64格式
   */
export function AES_CBC_ENCRYPT(text, secretKey) {
    const keyHex = CryptoJS.enc.Base64.parse(secretKey)
    const ivHex = keyHex.clone()
    // 前16字节作为向量
    ivHex.sigBytes = 16

    ivHex.words.splice(4)
    const messageHex = CryptoJS.enc.Utf8.parse(text)
    const encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
        'iv'     : ivHex,
        'mode'   : CryptoJS.mode.CBC,
        'padding': CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
}

/**
   * AES-256-CBC对称解密
   * @param textBase64 {string} 要解密的密文，Base64格式
   * @param secretKey {string} 密钥，43位随机大小写与数字
   * @returns {string} 解密后的明文
   */
export function AES_CBC_DECRYPT(textBase64, secretKey) {
    const keyHex = CryptoJS.enc.Base64.parse(secretKey)
    const ivHex = keyHex.clone()
    // 前16字节作为向量
    ivHex.sigBytes = 16
    ivHex.words.splice(4)
    const decrypt = CryptoJS.AES.decrypt(textBase64, keyHex, {
        'iv'     : ivHex,
        'mode'   : CryptoJS.mode.CBC,
        'padding': CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Utf8.stringify(decrypt)
}
