<template>
    <div>
        <transition
            name="fade-transform"
            mode="out-in"
        >
            <router-view

                :key="key"
            />
        </transition>
    </div>
</template>

<script>
export default {
    name    : 'AppMain',
    computed: {

        key() {
            return this.$route.path
        }
    },
    props: {
        newMsg: {
            type   : Number,
            default: null
        }
    }
}
</script>

