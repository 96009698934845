
import requestUrl from '../api/index'
const getWxConf = async() => {
    const url = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.entryUrl
    // const url = 'http://pre-mp.fkjz.cn'
    const { data } = await $request.postRequest({
        url: requestUrl.main.getTicketSignature,
        // url: '/go/getTicketSignature',

        params: {
            url: url
        }
    })

    // const res = {
    //     appId: 'wx28bec0cc70a23305'
    // }

    $wx.config({
        debug    : false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId    : data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr : data.noncestr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: [
            'checkJsApi',
            'getNetworkType',
            'getLocation',
            'scanQRCode'
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    })
    $wx.ready(() => {
        $wx.checkJsApi({
            jsApiList: ['getNetworkType', 'getLocation', 'scanQRCode'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success  : function(res) {
                // console.log('checkJsApi', res)
            }
        })
    })
    $wx.error((res) => {
        // console.log('wx.error', res)
    })
}
export {
    getWxConf
}
