import requestUrl from '@/api/index'
// import clonedeep from 'lodash.clonedeep'
// import { dataToArray, treeToArray, delEmptyChild } from '@/utils/index'
// import Vue from 'vue'
const state = {
    personnel: {
        audit_position_list: [],
        audit_user_list    : []
    },
    discountsProject: []

}

const mutations = {

    SET_PERSONNEL: (state, data) => {
        state.personnel = data
    },
    SET_DISCOUNTS_PROJECT: (state, data) => {
        state.discountsProject = data
    }

}

const actions = {
    getDiscountsProject({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.discounts.getDiscountsProject

            }).then(async response => {
                const { data } = response
                commit('SET_DISCOUNTS_PROJECT', data)
                resolve(data)
            })
        })
    },
    // 获取所有模板标签
    getPersonnel({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            $request.getRequest({
                url: requestUrl.discounts.personnel

            }).then(async response => {
                const { data } = response
                commit('SET_PERSONNEL', data)
                resolve(data)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
