import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import '@/styles/index.scss'
import store from './store'
import router from './router'
import { request } from './utils/request'

import Storage from 'vue-ls'
import requestUrl from './api/index'
import wx from 'weixin-js-sdk'
import './permission'
// import RightMenu from '@/views/customer'
// vue-ls 的配置
const storageOptions = {
    namespace: 'fk_', // key 键的前缀（随便起）
    name     : 'ls', // 变量名称（随便起） 使用方式：Vue.变量名称 或 this.$变量名称
    storage  : 'session' // 作用范围：local、session、memory
}
window.$request = request
Vue.config.productionTip = false
Vue.use(Storage, storageOptions)
Vue.prototype.$requestUrl = requestUrl
Vue.use(Vant)
window.$wx = wx
// console.log('wx', wx)
new Vue({
    el    : '#app',
    router,
    store,
    render: h => h(App)
})
